<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>Job P/L for {{ job.jobNumber }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <p>Customer: {{ job.customer }}</p>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <p>
          Site address:<br />
          {{ job.site }}<br />
          {{ job.postCode }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <p>
          Work description:
        </p>
        <div v-html="job.description"></div>
      </v-col>
    </v-row>
    <v-row v-if="job.materialUsages">
      <v-col cols="12" sm="12">
        <p>Material costs from DRS:</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Cost</th>
              <th>Quantity</th>
              <th class="text-right">Total</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td colspan="2">&nbsp;</td>
              <td colspan="2" class="text-right">Total: {{ drsUsageCosts | currency }}</td>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="(usage, index) in job.materialUsages" :key="index">
              <td>{{ usage.type }}</td>
              <td>{{ usage.cost | currency }}</td>
              <td>{{ usage.quantity }}</td>
              <td class="text-right">{{ usage.lineTotal | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="job.drsBoqs">
      <v-col cols="12" sm="12">
        <p>Material costs from reported BOQs:</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>BOQ</th>
              <th>Quantity</th>
              <th>M2</th>
              <th>Cost/M2</th>
              <th>Cost each</th>
              <th class="text-right">Total</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td colspan="4">&nbsp;</td>
              <td colspan="2" class="text-right">Total: {{ drsBoqCosts | currency }}</td>
            </tr>
          </tfoot>
          <tbody>
            <tr
              v-for="(drsBoq, index) in job.drsBoqs" :key="index">
              <td>{{ drsBoq.name }}</td>
              <td>{{ drsBoq.quantity }}</td>
              <td>{{ drsBoq.meterage }}</td>
              <td>{{ drsBoq.costPerM | currency }}</td>
              <td>{{ drsBoq.costEa | currency }}</td>
              <td class="text-right">{{ (drsBoq.quantity * drsBoq.costEa) | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="job.drsWorkers">
      <v-col cols="12" sm="12">
        <p>Crew:</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Pay bonus?</th>
              <th>Bonus</th>
              <th>Hours</th>
              <th>Hourly Rate</th>
              <th>Total Hours</th>
              <th>Hols Adj</th>
              <th>Total Wages</th>
              <th>Ni</th>
              <th>Overnight</th>
              <th class="text-right">Total cost</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td colspan="9">&nbsp;</td>
              <td colspan="2" class="text-right">Total: {{ workerCosts | currency }}</td>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="(crew, index) in job.drsWorkers" :key="index">
              <td>{{ crew.name }}</td>
              <td>
                <span v-if="crew.payBonus === true || crew.payBonus === 1">Yes</span>
                <span v-else>No</span>
              </td>
              <td>{{ crew.bonus | currency }}</td>
              <td>{{ crew.hours }}</td>
              <td>{{ crew.hourlyRate }}</td>
              <td>{{ crew.hoursCost | currency }}</td>
              <td>{{ crew.holidayAdjustment | currency }}</td>
              <td>{{ crew.totalWages | currency }}</td>
              <td>{{ crew.ni | currency }}</td>
              <td>{{ crew.overnight | priceInPounds | currency }}</td>
              <td class="text-right">{{ crew.total | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="job.costs">
      <v-col cols="12" sm="12">
        <p>Vehicle Costs:</p>
        <v-simple-table>
          <thead>
            <tr>
              <th style="width:20%;" class="text-left">Date</th>
              <th style="width:10%;" class="text-left">Mileage</th>
              <th style="width:20%;" class="text-left">Vehicle</th>
              <th style="width:10%;" class="text-left">MPG</th>
              <th style="width:10%;" class="text-left">Fuel/Gallon</th>
              <th style="width:10%;" class="text-left">Fuel</th>
              <th style="width:10%;" class="text-left">Running Costs</th>
              <th style="width:10%;" class="text-right">Total</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td colspan="5">&nbsp;</td>
              <td colspan="3" class="text-right">Total: {{ vehicleCosts | currency }}</td>
            </tr>
          </tfoot>
          <tbody>
            <tr>
              <td colspan="8">
                <v-simple-table v-for="(cost, index) in job.costs" :key="index">
                  <tr>
                    <td style="width:20%;">{{ cost.date | tinyDate }}</td>
                    <td style="width:10%; padding-left:8px;">{{ cost.mileage }}</td>
                    <td style="width:20%; padding-left:12px;">{{ cost.vehicle }}</td>
                    <td style="width:10%; padding-left:16px;">{{ cost.mpg }}</td>
                    <td style="width:10%; padding-left:16px;">{{ ((cost.fuel * 4.55) / 1000) | priceInPounds | currency }}</td>
                    <td style="width:10%; padding-left:20px;">{{ ((((cost.fuel * 4.55) / cost.mpg) * cost.mileage) / 1000) | priceInPounds | currency }}</td>
                    <td style="width:10%; padding-left:24px;">{{ (cost.mileage * cost.running_cost) | priceInPounds | currency }}</td>
                    <td style="width:10%;" class="text-right">{{ (((((cost.fuel * 4.55) / cost.mpg) * cost.mileage) / 1000) + (cost.mileage * cost.running_cost)) | priceInPounds | currency }}</td>
                  </tr>
                  <tr v-if="cost.vehicle_two">
                    <td style="width:20%;">{{ cost.date | tinyDate }}</td>
                    <td style="width:10%; padding-left:8px;">{{ cost.mileage }}</td>
                    <td style="width:20%; padding-left:12px;">{{ cost.vehicle_two }}</td>
                    <td style="width:10%; padding-left:16px;">{{ cost.mpg_vehicle_two }}</td>
                    <td style="width:10%; padding-left:16px;">{{ ((cost.fuel * 4.55) / 1000) | priceInPounds | currency }}</td>
                    <td style="width:10%; padding-left:20px;">{{ ((((cost.fuel * 4.55) / cost.mpg_vehicle_two) * cost.mileage) / 1000) | priceInPounds | currency }}</td>
                    <td style="width:10%; padding-left:24px;">{{ (cost.mileage * cost.running_cost_two) | priceInPounds | currency }}</td>
                    <td style="width:10%;" class="text-right">{{ (((((cost.fuel * 4.55) / cost.mpg_vehicle_two) * cost.mileage) / 1000) + (cost.mileage * cost.running_cost_two)) | priceInPounds | currency }}</td>
                  </tr>
                  <tr v-if="cost.vehicle_three">
                    <td style="width:20%;">{{ cost.date | tinyDate }}</td>
                    <td style="width:10%; padding-left:8px;">{{ cost.mileage }}</td>
                    <td style="width:20%; padding-left:12px;">{{ cost.vehicle_three }}</td>
                    <td style="width:10%; padding-left:16px;">{{ cost.mpg_vehicle_three }}</td>
                    <td style="width:10%; padding-left:16px;">{{ ((cost.fuel * 4.55) / 1000) | priceInPounds | currency }}</td>
                    <td style="width:10%; padding-left:20px;">{{ ((((cost.fuel * 4.55) / cost.mpg_vehicle_three) * cost.mileage) / 1000) | priceInPounds | currency }}</td>
                    <td style="width:10%; padding-left:24px;">{{ (cost.mileage * cost.running_cost_three) | priceInPounds | currency }}</td>
                    <td style="width:10%;" class="text-right">{{ (((((cost.fuel * 4.55) / cost.mpg_vehicle_three) * cost.mileage) / 1000) + (cost.mileage * cost.running_cost_three)) | priceInPounds | currency }}</td>
                  </tr>
                </v-simple-table>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="job.purchaseOrders">
      <v-col cols="12" sm="12">
        <p>Purchase Orders:</p>
        <v-simple-table>
          <thead>
          <tr>
            <th style="width:20%;" class="text-left">Date</th>
            <th style="width:10%;" class="text-left">Supplier</th>
            <th style="width:10%;" class="text-left">Amount</th>
          </tr>
          </thead>
          <tfoot>
          <tr>
            <th colspan="2">Total Purchase order costs</th>
            <th>&pound;{{ poCosts }}</th>
          </tr>
          </tfoot>
          <tbody>
          <tr v-for="(order, poindex) in job.purchaseOrders" :key="poindex">
            <td>{{ order.date_of_requisition | prettyDate }}</td>
            <td>{{ order.supplier.name }}</td>
            <td>&pound;{{ order.order_value }}</td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th>Waste disposal</th>
              <th>Programmers/surveyors/yard salaries (incl Ers NIC)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ job.wasteCost | currency }}</td>
              <td>{{ job.salaryCost | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th>Total cost of job</th>
              <th>Total amount invoiced</th>
              <th>Profit (loss)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ totalCost | currency }}</td>
              <td>{{ job.value | priceInPounds | currency }}</td>
              <td>{{ (job.value - (totalCost * 100)) | priceInPounds | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'ReportsJobPL',
  data() {
    return {
      job: {
        id: 0,
        costs: [],
        drsWorkers: [],
        drsBoqs: [],
      },
    };
  },
  computed: {
    userid() {
      return this.$store.state.userid;
    },
    token() {
      return this.$store.state.token;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
    drsBoqCosts() {
      let totalCost = 0;
      if (this.job.drsBoqs) {
        for (let i = 0; i < this.job.drsBoqs.length; i += 1) {
          totalCost += (this.job.drsBoqs[i].quantity * this.job.drsBoqs[i].costEa);
        }
      }
      return (totalCost);
    },
    drsUsageCosts() {
      let totalCost = 0;
      if (this.job.materialUsages) {
        for (let i = 0; i < this.job.materialUsages.length; i += 1) {
          totalCost += (this.job.materialUsages[i].quantity * this.job.materialUsages[i].cost);
        }
      }
      return totalCost;
    },
    workerCosts() {
      let totalCost = 0;
      if (this.job.drsWorkers) {
        for (let i = 0; i < this.job.drsWorkers.length; i += 1) {
          totalCost += this.job.drsWorkers[i].total;
        }
      }
      return totalCost;
    },
    vehicleCosts() {
      let totalCost = 0;
      if (this.job.costs) {
        for (let i = 0; i < this.job.costs.length; i += 1) {
          totalCost += (((((( this.job.costs[i].fuel * 4.55) / this.job.costs[i].mpg) * this.job.costs[i].mileage) / 1000) + (this.job.costs[i].mileage * this.job.costs[i].running_cost)) / 100);
          if (this.job.costs[i].vehicle_two) {
            totalCost += (((((( this.job.costs[i].fuel * 4.55) / this.job.costs[i].mpg_vehicle_two) * this.job.costs[i].mileage) / 1000) + (this.job.costs[i].mileage * this.job.costs[i].running_cost_two)) / 100);
          }
          if (this.job.costs[i].vehicle_three) {
            totalCost += (((((( this.job.costs[i].fuel * 4.55) / this.job.costs[i].mpg_vehicle_three) * this.job.costs[i].mileage) / 1000) + (this.job.costs[i].mileage * this.job.costs[i].running_cost_three)) / 100);
          }
        }
      }
      return totalCost;
    },
    poCosts() {
      let totalCost = 0;
      if (this.job.purchaseOrders) {
        for (let i = 0; i < this.job.purchaseOrders.length; i++) {
          totalCost += this.job.purchaseOrders[i].order_value;
        }
      }
      return totalCost;
    },
    totalCost() {
      let totalCost = 0;
      totalCost += this.vehicleCosts;
      totalCost += this.workerCosts;
      totalCost += this.drsBoqCosts;
      totalCost += this.drsUsageCosts;
      totalCost += this.poCosts;
      if (this.job.wasteCost) {
        totalCost += parseFloat(this.job.wasteCost.replace(/,/g, ''));
      }
      if (this.job.salaryCost) {
        totalCost += parseFloat(this.job.salaryCost.replace(/,/g, ''));
      }
      return totalCost;
    }
  },
  methods: {
    getJob() {
      const id = this.$route.params.id;
      axios.get(`/liveJobs/getSingleInfo/${id}.json?token=${this.token}`)
      .then((response) => {
        this.job = response.data;
      });
    },
  },
  mounted() {
    this.getJob();
  },
};
</script>
